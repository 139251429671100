var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"color":"cassiopee-purple","app":"","permanent":"","width":"305px"}},[_c('v-list',{staticClass:"pa-0 navigation-list"},_vm._l((_vm.items),function(item){return _c('v-list-group',{key:item.title,attrs:{"no-action":"","active-class":"active-item","append-icon":"","disabled":item.path === '/results'
            ? _vm.formIncomplete || _vm.datacenterInvalid
            : false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","disabled":item.path !== '/results'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":item.path !== '/results' ||
                  (!_vm.formIncomplete && !_vm.datacenterInvalid)
                    ? item.path
                    : undefined,"color":"transparent"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-4 ml-8"},[_c('v-icon',{attrs:{"size":"28","color":"white"}},[_vm._v(" "+_vm._s(item.action)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase text-h3"},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.title))])])],1)],1)]}}],null,true)},[(_vm.formIncomplete && item.path === '/results')?_c('span',[_vm._v(" Impossible d'accéder aux résultats, le formulaire est incomplet ")]):(_vm.datacenterInvalid && item.path === '/results')?_c('span',[_vm._v(" Impossible d'accéder aux résultats, le datacenter n'est pas encore validé dans Datakairos ")]):_c('span')])]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.anchorList),function(child){return _c('v-list-item',{key:child.title,staticClass:"mt-3",attrs:{"link":"","href":child.path}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" $basicArrow ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h3 text-wrap"},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(child.title))])])],1)],1)}),1)}),1)],1),_c('div',{staticClass:"cass-layout-full fill-height"},[_c('the-form-header'),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }