<template>
  <div>
    <v-navigation-drawer color="cassiopee-purple" app permanent width="305px">
      <v-list class="pa-0 navigation-list">
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          no-action
          active-class="active-item"
          append-icon=""
          :disabled="
            item.path === '/results'
              ? formIncomplete || datacenterInvalid
              : false
          "
        >
          <template #activator>
            <v-tooltip bottom :disabled="item.path !== '/results'">
              <template #activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  link
                  :to="
                    item.path !== '/results' ||
                    (!formIncomplete && !datacenterInvalid)
                      ? item.path
                      : undefined
                  "
                  color="transparent"
                  v-on="on"
                >
                  <v-list-item-icon class="mr-4 ml-8">
                    <v-icon size="28" color="white">
                      {{ item.action }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase text-h3">
                      <span class="white--text">{{ item.title }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <span v-if="formIncomplete && item.path === '/results'">
                Impossible d'accéder aux résultats, le formulaire est incomplet
              </span>
              <span v-else-if="datacenterInvalid && item.path === '/results'">
                Impossible d'accéder aux résultats, le datacenter n'est pas
                encore validé dans Datakairos
              </span>
              <span v-else />
            </v-tooltip>
          </template>
          <v-list-item
            v-for="child in item.anchorList"
            :key="child.title"
            link
            class="mt-3"
            :href="child.path"
          >
            <v-list-item-icon>
              <v-icon x-small>
                $basicArrow
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h3 text-wrap">
                <span class="white--text">{{ child.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <div class="cass-layout-full fill-height">
      <the-form-header />
      <slot />
    </div>
  </div>
</template>

<script>
import TheFormHeader from '@/components/TheFormHeader.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LayoutFull',
  components: {
    TheFormHeader,
  },
  data() {
    return {
      items: [
        {
          action: '$dashboardLight',
          title: 'Tableau de bord',
          path: '/dashboard',
          active: true,
        },
        {
          action: '$result',
          path: '/results',
          title: 'Résultats',
          anchorList: [
            {
              title: 'Capacité hébergement',
              path: '#capacite_hebergement',
            },
            {
              title: 'Niveau de risque',
              path: '#risque_hebergement',
            },
            {
              title: 'Impact environnement',
              path: '#impact_environnement',
            },
            {
              title: 'Coûts hébergements',
              path: '#couts_hebergements',
            },
            {
              title: 'Analyse du coût de votre hébergement actuel',
              path: '#analyse_cout_hebergement_actuel',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('form', ['formIncomplete']),
    ...mapGetters('authentication', ['datacenterInvalid']),
  },
  methods: {
    ...mapActions('authentication', ['signOut']),
    logout() {
      this.signOut().then(() => {
        this.$router.push('/login');
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

a {
  text-decoration: none;
}
.navigation-list {
  margin-top: 106px;
}
.active-item {
  background: map-deep-get($colors, 'cassiopee-purple', 'darken-1') !important;
  border-left: 7px solid white !important;
  border-radius: 0px;
  opacity: 1;
}
</style>
